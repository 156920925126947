<template>
  <div class="max-w-md text-white">
    <div class="px-4 py-4 sm:px-6">
      <div
        class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap"
      >
        <div class="ml-4 mt-2">
          <h3 class="text-xs md:text-base md:leading-6">
            SHARE YOUR ACHIEVEMENT
          </h3>
        </div>
        <div
          class="ml-4 mt-2 flex-shrink-0 cursor-pointer"
          @click="$emit('hideShare', true)"
        >
          <XMarkIcon class="h-4 w-4 my-0.5" />
        </div>
      </div>
    </div>
    <div class="share-card">
      <div
        class="mb-5 mx-auto relative"
        :style="{
          'background-image': 'url(' + banner + ')',
          width: '350px',
          height: '270px',
        }"
      >
        <p
          class="font-extrabold text-gray-500 text-2xl leading-7 right-40 absolute"
          :class="{ 'bottom-24': level == '2nd', 'bottom-1/3': level != '2nd' }"
        >
          {{ challenge }}
        </p>
        <p
          v-if="level != '1st'"
          class="font-extrabold text-gray-500 text-2xl leading-7 bottom-14 left-8 absolute"
        >
          {{ challenge }}
        </p>
        <p
          v-if="level == '3rd'"
          class="font-extrabold text-gray-500 text-2xl leading-7 bottom-14 right-7 absolute"
        >
          {{ challenge }}
        </p>
      </div>
      <p class="text-sm">
        Hey everyone! I have just passed the {{ level }} stage on
        <span class="share-hashtag"> #WFYTtradechallenge</span>,
        <span v-if="level != '3rd'"
          >{{ level == "1st" ? "two" : "one" }} more to become a Pro!</span
        >
        <span v-else> and now a Pro!</span>
      </p>
    </div>
    <div>
      <p>Share on:</p>
      <div class="flex justify-evenly mx-20 mt-5">
        <s-twitter
          v-if="enabledShareButtons.includes('twitter')"
          :window-features="windowFeatures"
          :share-options="twitterShareOptions"
          :use-native-behavior="useNativeBehavior"
          @popup-close="onClose"
          @popup-open="onOpen"
          @popup-block="onBlock"
          @popup-focus="onFocus"
        >
          <img :src="require(`@/assets/social-media/twitterx.svg`)" />
        </s-twitter>
        <s-facebook
          v-if="enabledShareButtons.includes('facebook')"
          :window-features="windowFeatures"
          :share-options="facebookShareOptions"
          :use-native-behavior="useNativeBehavior"
          @popup-close="onClose"
          @popup-open="onOpen"
          @popup-block="onBlock"
          @popup-focus="onFocus"
        >
          <img :src="require(`@/assets/social-media/facebook.svg`)" />
        </s-facebook>

        <img
          v-if="enabledShareButtons.includes('discord')"
          :src="require(`@/assets/social-media/discord.svg`)"
        />
        <img
          v-if="enabledShareButtons.includes('instagram')"
          :src="require(`@/assets/social-media/instagram.svg`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { XMarkIcon } from "@heroicons/vue/24/solid";

export default {
  // metaInfo() {
  //   return {
  //     title: "#WFYTtradechallenge",
  //     link: [
  //       {
  //         rel: "canonical",
  //         href:
  //           "https://widgets.wfyt.tinkerpub.com/#/achievements/new?s=" +
  //             this.level ==
  //           "1st"
  //             ? 1
  //             : this.level == "2nd"
  //             ? 2
  //             : 3 + "&c=" + this.challenge,
  //       },
  //     ],
  //     meta: [
  //       {
  //         vmid: "description",
  //         name: "description",
  //         content: `Hey everyone! I have just passed the ${
  //           this.level
  //         } stage on #WFYTtradechallenge ${
  //           this.level == "1st"
  //             ? "two more to become a Pro!"
  //             : this.level == "2nd"
  //             ? "one more to become a Pro!"
  //             : "and now a Pro!"
  //         } `,
  //       },
  //       {
  //         property: "og:description",
  //         content: `Hey everyone! I have just passed the ${
  //           this.level
  //         } stage on #WFYTtradechallenge ${
  //           this.level == "1st"
  //             ? "two more to become a Pro!"
  //             : this.level == "2nd"
  //             ? "one more to become a Pro!"
  //             : "and now a Pro!"
  //         } `,
  //       },
  //       {
  //         property: "og:title",
  //         content: "#WFYTtradechallenge",
  //       },
  //       {
  //         property: "og:type",
  //         content: "website",
  //       },
  //       {
  //         property: "og:image",
  //         content: this.banner,
  //       },
  //       {
  //         property: "og:image:url",
  //         content: this.banner,
  //       },
  //       {
  //         property: "og:image:secure_url",
  //         content: this.banner,
  //       },
  //       {
  //         property: "og:image:width",
  //         content: "1200",
  //       },
  //       {
  //         property: "og:image:height",
  //         content: "627",
  //       },
  //       {
  //         property: "og:image:alt",
  //         content: "#WFYTtradechallenge",
  //       },
  //       {
  //         property: "og:url",
  //         content:
  //           "https://widgets.wfyt.tinkerpub.com/#/achievements/new?s=" +
  //             this.level ==
  //           "1st"
  //             ? 1
  //             : this.level == "2nd"
  //             ? 2
  //             : 3 + "&c=" + this.challenge,
  //       },
  //       {
  //         property: "og:site_name",
  //         content: "WFYT",
  //       },
  //       {
  //         property: "og:author",
  //         content: "WFYT",
  //       },
  //       // {
  //       //   vmid: 'publish_date',
  //       //   name: 'publish_date',
  //       //   property: "og:publish_date",
  //       //   content: null,
  //       // },
  //       // {
  //       //   vmid: 'published_time',
  //       //   name: 'published_time',
  //       //   property: "article:published_time",
  //       //   content: null,
  //       // },
  //       {
  //         property: "twitter:card",
  //         content: "summary",
  //       },
  //       {
  //         property: "twitter:domain",
  //         content: "https://widgets.wfyt.tinkerpub.com/",
  //       },
  //       {
  //         property: "twitter:url",
  //         content:
  //           "https://widgets.wfyt.tinkerpub.com/#/achievements/new?s=" +
  //             this.level ==
  //           "1st"
  //             ? 1
  //             : this.level == "2nd"
  //             ? 2
  //             : 3 + "&c=" + this.challenge,
  //       },
  //       {
  //         property: "twitter:title",
  //         content: "#WFYTtradechallenge",
  //       },
  //       {
  //         property: "twitter:text:title",
  //         content: "#WFYTtradechallenge",
  //       },
  //       {
  //         property: "twitter:description",
  //         content: `Hey everyone! I have just passed the ${
  //           this.level
  //         } stage on #WFYTtradechallenge ${
  //           this.level == "1st"
  //             ? "two more to become a Pro!"
  //             : this.level == "2nd"
  //             ? "one more to become a Pro!"
  //             : "and now a Pro!"
  //         } `,
  //       },
  //       {
  //         property: "twitter:image",
  //         content: this.banner,
  //       },
  //     ],
  //   };
  // },
  components: {
    XMarkIcon,
  },
  props: {
    level: {
      type: String,
      default: "3rd",
    },
    challenge: {
      type: String,
      default: "10K",
    },
  },
  data() {
    return {
      banner: null,
      windowFeatures: {},
      enabledShareButtons: process.env.VUE_APP_ENABLED_SHARE_BUTTONS
        ? process.env.VUE_APP_ENABLED_SHARE_BUTTONS.split(",")
        : [],
      facebookShareOptions: {
        url: window.location.href,
        quote: `Hey everyone! I have just passed the ${
          this.level
        } stage on #WFYTtradechallenge ${
          this.level == "1st"
            ? "two more to become a Pro!"
            : this.level == "2nd"
            ? "one more to become a Pro!"
            : "and now a Pro!"
        } `,
        hashtag: "#WFYTtradechallenge",
      },
      twitterShareOptions: {
        url: window.location.href,
        text: `Hey everyone! I have just passed the ${
          this.level
        } stage on #WFYTtradechallenge ${
          this.level == "1st"
            ? "two more to become a Pro!"
            : this.level == "2nd"
            ? "one more to become a Pro!"
            : "and now a Pro!"
        } `,
      },
      useNativeBehavior: false,
      metas: null,
    };
  },
  methods: {
    onClose() {},
    onOpen() {},
    onBlock() {},
    onFocus() {},
  },
  computed: {
    currentUrl() {
      return window.location.href;
    },
  },
  created() {
    switch (this.level) {
      case "1st":
        this.banner = require(`@/assets/share/level-1.svg`);
        break;
      case "2nd":
        this.banner = require(`@/assets/share/level-2.svg`);
        break;
      case "3rd":
        this.banner = require(`@/assets/share/level-3.svg`);
        break;
      default:
        this.banner = require(`@/assets/share/level-1.svg`);
        break;
    }
  },
  mounted() {},
};
</script>

<style lang="postcss" scoped>
.share-card {
  border-radius: 4px;
  background: #171717;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4) inset;
  @apply p-6 m-5;
}
.share-hashtag {
  color: #e1d1b6;
}
</style>
